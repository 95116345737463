import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Link from "gatsby-link";
import Layout from "../../layouts";
import { NavBar, PostListItem, CardSignup } from "../../components";
import { artifacts } from "../../utils/icons";
import "./blog.css";

const pageQuery = graphql`
  query pageQuery {
    allContentfulPost(sort: { fields: date, order: DESC }) {
      nodes {
        content {
          childMarkdownRemark {
            excerpt
            id
          }
        }
        date(formatString: "MMMM DD, YYYY")
        title
        category
      }
    }
  }
`;

const BlogList = () => {
  return (
    <StaticQuery
      query={pageQuery}
      render={({ allContentfulPost }) => {
        const posts = allContentfulPost.nodes.map((node, i) => (
          <PostListItem key={i} {...node} />
        ));
        return (
          <Layout>
            <header className="blog-header tc-white ">
              <NavBar />
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "400px",
                  top: "0px",
                }}
              >
                {artifacts}
              </div>
              <div className="container header-content animate-bg">
                <div className="breadcrumbs">
                  <Link className="breadcrumb-item" to="/">
                    Home
                  </Link>
                  <span>Blog</span>
                </div>
                <h1 className=" blog-page-title">
                  <div className="title-text">The Investment</div>
                  <div className="title-text">Calculator Blog</div>
                </h1>
                <p className="blog-page-subtitle">
                  Some of our best resources to think about your retirement
                </p>
              </div>
            </header>
            <div className="container">
              <div className="blog-layout">
                <div className="post-list-container animate">{posts}</div>
                <div className="blog-sidebar animate">
                  <CardSignup />
                </div>
              </div>
            </div>
          </Layout>
        );
      }}
    ></StaticQuery>
  );
};

export default BlogList;
